import React, { memo } from 'react';
import { Spinner } from 'dvmp-components';
import { useSelector } from 'react-redux';

const _style = {
   position: 'absolute',
   width: '100%', 
   height: '100%', 
   backgroundColor: 'rgba(0,0,0,0.35)', 
   zIndex: '9999999',
};

const FetchingIndicator = memo(({ selector, shadow = false }) => {
   
   const fetching = useSelector(selector.fetching);
   const error = useSelector(selector.error);
   const data = useSelector(selector.data);

   const style = shadow ? _style : { };

   return(
      (fetching === true && error === false) ? (
         <div className="d-flex justify-content-center align-items-center" style={style}><Spinner timeout={0}/></div>
      ) : (
         (error === true) ? (
            <div className="d-flex flex-column justify-content-center align-items-center" style={style}>
               <div><i className="fa fa-times-circle text-danger fa-2x" /></div>
               <div className="text-danger">{data.message?.text || ""}</div>
            </div>
         ) : null
      )
   );
});

export default FetchingIndicator;