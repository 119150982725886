import React, { useRef, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import TimerWorker from 'workerize-loader!dvmp-components/dist/TimerWorker.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax

/**
 *
 * @param {Object} logout logout.time [minutes], logout.logout - funkce, kterou volame pro odhlaseni uzivatele
 * @param {Object} warn warn.time [minutes], warn.message, warn.header, warn.no_logout, warn.logout
 * @param {function} onActivity funkce kterou volame pri aktivite uzivatele
 * @param {array} events Seznam eventu, ktere bereme jako aktivitu uzivatele
 */
const AutomaticLogout = React.memo(function AutomaticLogout({ logout, warn, onActivity, events = [ "click", "mousedown", "scroll", "keypress" ] }){

   const timer_worker = useRef();

   const [ showWarn, setShowWarn ] = useState(false);

   const toggleWarnModal = (reset = true) => {
      if(reset === true) resetTimer();
      setShowWarn(!showWarn);
   };

   const addTimer = () => {
      timer_worker.current.setLogoutTimer(logout.time);
      timer_worker.current.setWarnTimer(warn.time);
   };
   logout
   const removeTimer = () => {
      timer_worker.current.cancelWarnTimer();
      timer_worker.current.cancelLogoutTimer();
   };

   const resetTimer = () => {
      //console.log("reseting timers");
      removeTimer();
      addTimer();
      onActivity && onActivity();
   };

   useEffect(() => {
      if(warn.time > logout.time) console.error("Doba pro odhlášení je menší, než doba pro varování, že proběhne odhlášení!!");
	   //timer_worker.current = new Worker('dvmp-components/dist/TimerWorker.worker.js', { type: 'module'} );
      if(window.Worker){
         timer_worker.current = TimerWorker();
         timer_worker.current.onmessage = function(e){
            //console.log(e);
            try{
               if(e.data.type === "timeout"){
                  switch(e.data.result){
                     case "warn":
                        //console.log("warn modal");
                        toggleWarnModal(false);
                        break;
                     case "logout":
                        //console.log("konam logout")
                        logout.logout();
                        break;
                     default:
                        //console.log("nerozpoznana akce: ", e);
                        break;
                  }
               }
            } catch(e){}
         };
      }
      else{
         console.error("Web Workers not supported!");
      }

      addTimer();
      for(const event of events){
         window.addEventListener(event, resetTimer);
      }

      return() => {
         removeTimer();
         for(const event of events){
            window.removeEventListener(event, resetTimer);
         }
	 //pada to, ze unregister neexistuje
         //timer_worker.current.unregister();
      };
   }, [ ]);

   return(
         <Modal isOpen={showWarn}>
            <ModalHeader>{warn.header || "Automatické odhlášení"}</ModalHeader>
            <ModalBody>{warn.message || 'Brzy proběhne automatické odhlášení z důvodu neaktivity. Chcete-li odhlášení předejít, klikněte na "Neodhlašovat"'}</ModalBody>
            <ModalFooter className="float-right">
               <Button size="sm" color="danger" onClick={logout.logout}>{warn.logout || "Odhlásit"}</Button>
               <Button className="ml-1" size="sm" color="success" onClick={toggleWarnModal}>{warn.no_logout || "Neodhlašovat"}</Button>
            </ModalFooter>
         </Modal>
   );

});

AutomaticLogout.propTypes = {
   logout: PropTypes.shape({
      //cas, po kterem probehne odhlaseni (musi byt vetsi nez cas, po kterem probehne odhlaseni)
      time: PropTypes.number,
      //funkce, ktera se vola pri odhlaseni
      logout: PropTypes.func.isRequired,
   }),
   warn: PropTypes.shape({
      //cas, po kterem se zobrazi hlaska o tom, ze probehne odhlaseni
      time: PropTypes.number,
      //preklad pro obsah modalu s informaci o odhlaseni
      message: PropTypes.string,
      //preklad pro titulek modalu s informaci o odhlaseni
      header: PropTypes.string,
      //preklad pro "neodhlasovat"
      no_logout: PropTypes.string,
      //preklad pro "odhlasit se"
      logout: PropTypes.string,
   }),
   //funkce kterou volame, pokud uzivatel vykazuje aktivitu
   onActivity: PropTypes.func,
   //seznam listener eventu, ktere povazujeme jako aktivitu uzivatele
   events: PropTypes.array,
};

export default AutomaticLogout;
