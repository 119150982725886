import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Spinner from '../ApiLoading/Spinner';
import { isNull, isObject, isString, isBool, isArray } from '../ApiLoading/Checkers';

const check = {
   null: isNull,
   object: isObject,
   string: isString,
   bool: isBool,
   array: isArray,
};

export default function ApiLoading2(data, checker = 'null', size = "", timeout = 300){
   
   const fa_class = (size === "") ? "fa-2x" : "";

   const style_error = {
      //bootstrap danger
      color: "#dc3545",
   };

   const [ last_state, setLastState ] = useState(1);

   const isLoading = () => {
      console.log(typeof check[checker])
      //pokud to neni pole, tak rovnou overime jestli probiha loading
      if(typeof data !== 'array'){
         return check[checker](data);
      }
      //pokud to je pole, tak projedeme vsechny prvky a hledame, jestli nejaky nenacita
      let isNotLoaded = false;
      for(let i of data){
          //neco se pokazilo, takze automaticky vracime ze jsme pokazeni
          const status = check[checker](i)
          if(status === 2) return 2;
          //cekame na data, ale neukoncujeme podminku, protoze muzeme dal narazit jeste na chybu, ktera ma vetsi prioritu
          if(status === 1 ) isNotLoaded = true;
      }
      //pokud nacitame, tak vratime nacitani
      if(!!isNotLoaded) return 1;
      //vsechno ok, zobrazime children
      return 0;

  };

   useEffect(() => {
      const loading = isLoading();
      setLastState(loading)
   }, [ data ]);

   const Output = React.useCallback(() => (
      (last_state === 0) ? null : 
         <Row>
            <Col className="d-flex justify-content-center mt-2 mb-2">
                  {
                     (last_state === 1) ? <Spinner size={size} timeout={timeout} size={size} /> 
                        : <i className={`fas fa-times-circle ${fa_class}`} style={style_error} />
                  }
            </Col>
         </Row>
   ), [ last_state ]);

   return [ last_state === 0, Output ];
}