/**
 * return 0 - nacteno, predavame children
 * return 1 - nemame data, zobrazime loader
 * return 2 - ouou, neco se pokazilo, zobrazime x
 */

export const isNull = data => {
   if(data === null) return 1;
   else if(typeof data === typeof undefined) return 2;
   return 0;
};

export const isObject = data => {
   if(typeof data === typeof {} && Object.keys(data).length === 0) return 1;
   else if (typeof data !== typeof {}) return 2;
   return 0;
};

export const isArray = data => {
   if(typeof data === typeof [] && data.length === 0) return 1;
   else if(typeof data !== typeof []) return 2;
   return 0;
};

export const isString = data => {
   if(data === "") return 1;
   else if(typeof data === typeof undefined) return 2;
   return 0;
};

export const isBool = data => {
   if(typeof data === typeof undefined) return 2;
   if(data === false) return 1;
   return 0;
}
