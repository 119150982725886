import React, { useState, useRef, useEffect } from 'react';
import { Spinner as Spin } from 'reactstrap';

const Spinner = React.memo(({ timeout = 300, size }) => {

    const [ spining, setSpining ] = useState(false);
    const timer = useRef();

    const timing = () => {
        setSpining(true);
    };

    useEffect(() => {
        timer.current = setTimeout(() => timing(), timeout);
        return(() => {
            clearTimeout(timer.current);
        });
    });

    return(
        (spining === true ) ? <Spin color="primary" size={size} /> : null
    );
});

export default Spinner;