 import React, { useRef, Fragment } from 'react';

/**
 * Hook pro rekurzní kreslení tabulky (groupování)
 * @usage:
 * props.data, pokud mame property subtable, tak na tyto data volame Table, cili nam z tech dat vykresli subtable v dane tabulce
 *
 * @param {object} props props parentu
 * @param {object} Table komponenta, kterou budeme zobrazovat v rekurzi (vicemene predavame danou komponentu, ve ktere pouzijeme hook)
 * @param {string} key Jmeno property, ktere pouzivame pro table keyField
 * @return Objekt pro parametr expandRow
 */
export default function TableWithRecursion(props, Table, key){

   //seznam radku, ktere nebudou expandable
   const nonExpandable = useRef([]);

   //najdeme vsechny radky, co nemaji subtable a tyto pridame do pole nonExpandable
   if(nonExpandable.current.length < 1){
       for(const row of props.data){
           //pokud nemame subtable v datech, tak pridame do nonExpendable
           if(!row.hasOwnProperty('subtable')) {
               nonExpandable.current.push(row[key]);
           }
       }
   }

   //object expandRow je kompatibilni s parametrem expandRow pro react-bootstrap-table-next (muzeme ho rovnou predavat do komponenty, neni-li potreba upravovat)
   const expandRow = {
      renderer: (row, row_id) => (
         (typeof row.subtable !== typeof undefined) ?
            <Fragment>
               <div className="table-offer-second-line"></div>
               <Table
                  {...props}
                  data={row.subtable.data}
                  columns={row.subtable.columns}
               />
            </Fragment>
         : null
      ),
      showExpandColumn: true,
      nonExpandable: nonExpandable.current,
  }

   return expandRow;
}
