import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export default function UncontrolledModal({ toggle = () => {}, element = "", header_text="", children, close_text = "Zavřít" }){

    const [ open, setOpen ] = useState(false);
    const element_ref = useRef();

    const toggleModal = () => {
        setOpen(prev_state => !prev_state);
    };

    useEffect(() => {
        if(element !== ""){
	    element_ref.current = document.getElementById(element);
	    if(element_ref.current !== null){
                element_ref.current.addEventListener("click", toggleModal);
	    }
	    else{
	        console.error("Element with id: "+element+" not found in document!");
	    }
        }
        return(() => {
	    if(element_ref.current !== null){
		element_ref.current.removeEventListener("click", toggleModal);
		element_ref.current = null;
	    }
        });
    }, []);

    return(
        <Modal isOpen={open}>
            <ModalHeader>{header_text}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <Button color="primary" onClick={toggleModal}>{close_text}</Button>
            </ModalFooter>
        </Modal>
    );
}
